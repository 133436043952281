<template>
  <div class="footer">
    <div class="icon">
      <i v-for="(item, i) in foot_imgs" :key="i" @click="handleQR(item)">
        <img :src="item.img" />
        <span v-if="item.text">{{ item.text }}</span>
      </i>
    </div>
    <div class="tel">
      <img :src="telImg" alt="">
      <span>全国客户服务热线</span>
      <i>|</i>
      <a href="tel://4008886999">400 888 6999</a>
    </div>
    <el-collapse v-model="activeNames" @change="handleChange" accordion>
      <el-collapse-item v-for="item in list" :title="item.text" :name="item.text" :key="item.text">
        <div v-for="link in item.children" :key="link.text" @click="handleJump(link, item)">
          {{ link.text }}
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="copy">
      <p>©2024 江苏新日电动车股份有限公司</p>
      <p>公司地址:江苏省无锡市锡山区同慧街101号（新日大厦）</p>
      <p @click="handeleBA">苏ICP备12037028号-1</p>
    </div>
    <div class="side">
      <ul>
        <li v-for="(item, i) in side" :key="item.img" class="nav">
          <div @click="handleNav(item, i)">
            <span>
              <img :src="item.img" alt="">
            </span>
            <b>{{ item.text }}</b>
          </div>
          <div v-if="item.show" class="ol_bg" @click="handleNav(item, i)" />
          <ol v-if="item.show">
            <li v-for="sub in item.children" :key="sub.text" @click="handleNav(sub)">
              {{ sub.text }}
            </li>
          </ol>
        </li>
      </ul>
    </div>
    <div class="qr_bg" v-if="showQR" @click="handleQR()" />
    <div class="qr" v-if="showQR">
      <img :src="showQR">
    </div>
  </div>
</template>

<script>
import wechat from '@/assets/new/footer/footer_wechat_m.png'
import sina from '@/assets/new/footer/footer_weibo_m.png'
import customer from '@/assets/new/footer/footer_customer_m.png'
import douyin from '@/assets/new/footer/footer_douyin_m.png'
import lang from '@/assets/new/footer/footer_international_m.png'

import tel from '@/assets/common/tel.png'

import testdrive from '@/assets/new/footer/testdrive_m.png'
import buy from '@/assets/new/footer/buy_m.png'
import customer1 from '@/assets/new/footer/customer_m.png'

import wechat_qr from '@/assets/footer/weixin.png'
import sina_qr from '@/assets/footer/weibo.png'
import douyin_qr from '@/assets/footer/douyin.jpeg'

export default {
  name: 'FooterComponent',
  props: {
    msg: String
  },
  data() {
    return {
      activeNames: ['1'],
      side: [
        {
          img: testdrive,
          text: '预约试骑',
          path: '/TestDrive'
        },
        {
          img: buy,
          text: '在线购买',
          link: '',
          show: false,
          children: [
            {
              text: '天猫旗舰店',
              link: 'https://sunra.tmall.com/shop/view_shop.htm?spm=a230r.1.14.29.751d1136FriHqk&user_number_id=3299935258',
              title: '在线购买',
            }, {
              text: '京东官方旗舰店',
              link: 'https://mall.jd.com/index-65252.html?from=pc',
              title: '在线购买',
            }, {
              text: '京东自营旗舰店',
              link: 'https://mall.jd.com/index-1000324841.html?from=pc',
              title: '在线购买',
            }, {
              text: '拼多多官方旗舰店',
              link: 'https://mobile.yangkeduo.com/mall_page.html?_x_org=1&refer_share_uin=TV2UKSUX2PEQNPUVG5NZH3KRKY_GEXDA&refer_share_id=SQEPgOziGElk4c8T7TyVzImJqDOikHid&_x_query=%E6%96%B0%E6%97%A5qc52.0&_wv=41729&refer_share_channel=copy_link&msn=4kunvhllu3hbumsrzgh2ynxm24_axbuy&has_decoration=1&mall_id=624046988&_wvx=10',
              title: '在线购买',
            }
          ],
        },
        {
          img: customer1,
          text: '在线客服',
          link: 'https://xinri.s4.udesk.cn/im_client/?web_plugin_id=236&group_id=45',
          title: '在线客服',
        },
      ],
      showQR: '',
      foot_imgs: [
        {
          img: wechat,
          code: wechat_qr,
          point_text: '微信'
        }, {
          img: sina,
          code: sina_qr,
          link: 'https://weibo.com/xinriddc',
          point_text: '微博'
        }, {
          img: customer,
          link: 'https://xinri.s4.udesk.cn/im_client/?web_plugin_id=236&group_id=45',
          point_text: '客服'
        }, {
          img: douyin,
          code: douyin_qr,
          point_text: '抖音'
        }, {
          img: lang,
          link: 'https://www.sunraev.com/',
          text: 'international',
          point_text: '国际站'
        }
      ],
      telImg: tel,
      list: [
        {
          text: '品牌合作',
          children: [
            {
              text: '成为经销商',
              path: '/Dealer'
            }, {
              text: '成为供应商',
              path: '/Supplier'
            }, {
              text: '下载中心',
              path: '/Download',
              // path: 'https://www.xinri.com/download'
            }, {
              text: '联系我们',
              path: '/JoinUs'
            }
          ]
        }, {
          text: '新日动态',
          children: [
            {
              text: '新日里程',
              path: '/MileageView'
            },
            {
              text: '最新资讯',
              path: '/News'
            },
            {
              text: '投资者关系',
              path: 'http://www.sse.com.cn/assortment/stock/list/info/announcement/index.shtml?productId=603787',
              blank: true,
            }, 
            // {
            //   text: '新日公示',
            //   path: '/Publicity'
            // }
          ]
        }, {
          text: '在线购买',
          children: [
            {
              text: '天猫旗舰店',
              path: 'https://shop589328963.m.taobao.com/',
              blank: true,
            }, {
              text: '京东官方旗舰店',
              path: 'https://shop.m.jd.com/?shopId=65252&utm_source=iosapp&utm_medium=appshare&utm_campaign=t_335139774&utm_term=CopyURL&ad_od=share&gx=RnFtxGNZPTGPwtRP--sGI8jF9Nz7qvUXwWw',
              blank: true,
            }, {
              text: '京东自营旗舰店',
              path: 'https://shop.m.jd.com/?shopId=1000324841&utm_source=iosapp&utm_medium=appshare&utm_campaign=t_335139774&utm_term=CopyURL&ad_od=share&gx=RnFtxGNZPTGPwtRP--sGI8jF9Nz7qvUXwWw',
              blank: true,
            }, {
              text: '拼多多官方旗舰店',
              path: 'https://mobile.yangkeduo.com/mall_page.html?_x_org=1&refer_share_uin=TV2UKSUX2PEQNPUVG5NZH3KRKY_GEXDA&refer_share_id=SQEPgOziGElk4c8T7TyVzImJqDOikHid&_x_query=%E6%96%B0%E6%97%A5qc52.0&_wv=41729&refer_share_channel=copy_link&msn=4kunvhllu3hbumsrzgh2ynxm24_axbuy&has_decoration=1&mall_id=624046988&_wvx=10',
              blank: true,
            }
          ]
        }, {
          text: '车主服务',
          children: [
            {
              text: '网点查询',
              path: '/OutletQuery'
            }, {
              text: '一键救援',
              path: '/Rescue'
            }, {
              text: '预约售后',
              path: '/AfterSale'
            }, {
              text: '防伪查询',
              path: '/AntFake'
            }, 
            // {
            //   text: '召回公告',
            //   path: '/CallBack'
            // }
          ]
        }, {
          text: '招贤纳士',
          children: [
            {
              text: '校园招聘',
              path: '/CampusRecruit'
            }, {
              text: '社会招聘',
              path: '/SocialRecruit'
            }
          ]
        }, {
          text: '投诉举报',
          children: [
            {
              text: '在线投诉',
              path: '/Complaint',
              // blank: 'inside',
            }
          ]
        }
      ]
    }
  },
  methods: {
    handeleBA() {
      window.open('https://beian.miit.gov.cn/');
      window._hmt.push(['_trackEvent', '底部导航栏', 'ICP查询', 'ICP查询']);
    },
    handleJump(item, f) {
      window._hmt.push(['_trackEvent', '底部导航栏', f.text, item.text]);
      if (item.path && item.blank === true) {
        window.open(item.path, '_blank')
      } else if (item.path && item.blank === 'inside') {
        location.href = item.path
      } else if (item.path) {
        this.$router.push(item.path)
      }
    },
    handleNav(item, i) {
      let newItem = this.side
      if (item.link) {
        window._hmt.push(['_trackEvent', '底部浮窗', item.title, item.text]);
        newItem = newItem.map(
          (v) => ({
            ...v,
            show: false,
          }),
        )
        window.open(item.link, '_blank')
      } else if (item.children) {
        newItem[i].show = !newItem[i].show
      } else if (item.path) {
        window._hmt.push(['_trackEvent', '底部浮窗', item.text, item.text]);
        if (this.$route.meta.id) {
          this.$router.push({ path: item.path, query: { carID: this.$route.meta.id } })
        } else {
          this.$router.push(item.path)
        }
      }
      this.side = newItem
    },
    handleChange(val) {
      console.log(val)
    },
    handleQR(item) {
      if (item?.link) {
        window.open(item.link, '_blank')
      } else {
        this.showQR = item?.code || ''
      }
      if (item?.point_text) {
        window._hmt.push(['_trackEvent', '底部导航栏', item.point_text, item.point_text]);
        console.log('底部导航栏', item.point_text, item.point_text)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  position: relative;
  background: #000;
  width: calc(100% - 75px - 75px);
  padding: 0 75px;
  padding-bottom: 120px;
  z-index: 100;
}

.icon {
  display: flex;
  justify-content: space-between;
  padding: 69px 0 66px;

  i {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    height: 46px;
    width: auto;
  }

  span {
    color: #6e6e6e;
    font-size: 28px;
    font-style: normal;
    margin: 0 0 0 4px;
  }
}

.tel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  margin: 0 0 72px;

  img {
    display: block;
    width: auto;
    height: 32px;
  }

  span {
    font-size: 22px;
    color: #95999f;
  }

  i {
    width: 32px;
    font-size: 14px;
    color: #95999f;
    font-style: normal;
    text-align: center;
  }

  a {
    font-size: 22px;
    color: #ffc300;
    text-decoration: none;
  }
}

.el-collapse {
  border-width: 0;
}

.el-collapse-item {
  &:last-child ::v-deep .el-collapse-item__header {
    border: 0;
  }

  &:last-child ::v-deep .el-collapse-item__wrap {
    border: 0;
  }
}

.el-collapse-item ::v-deep .el-collapse-item__header {
  font-size: 22px;
  color: #fff;
  background: #000;
  border-color: rgba(87, 87, 87, 0.6);
  height: 74px;

  .el-icon-arrow-right:before {
    color: #fff;
    font-weight: 1000;
  }

  .el-collapse-item__arrow {
    margin-right: 0;
    transform: rotate(90deg);
  }

  .el-collapse-item__arrow.is-active {
    transform: rotate(-90deg);
  }
}

.el-collapse-item ::v-deep .el-collapse-item__wrap {
  background: #000;
  border-color: rgba(87, 87, 87, 0.6);

  .el-collapse-item__content {
    padding: 20px 0 10px;
    color: rgba(255, 255, 255, 0.3);
    line-height: 60px;
    font-size: 22px;

    &>div {
      margin: 0 0 20px;
      font-size: 20px;
      line-height: 1;
    }
  }
}

.copy {
  margin: 24px 0 40px;
  padding: 10px 0 18px;
  color: #95999f;
  font-size: 14px;
  line-height: 30px;

  p {
    padding: 0 0 10px;
    margin: 0;
  }
}

.side {
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  height: 120px;
  background: rgba(255, 255, 255, 0.9);
  margin: 0 -75px -1px;
  font-family: "HarmonyOS_Sans_SC_Medium";

  ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .nav {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  img {
    width: 60px;
    margin: 0 auto;
    display: block;
  }

  b {
    margin: 8px 0 0;
    display: block;
    font-size: 20px;
    color: #000;
    text-align: center;
  }

  ol {
    display: block;
    position: absolute;
    right: -20px;
    bottom: 120px;
    left: -20px;
    z-index: 100;
    background: #fff;
    margin: 0;
    padding: 0;

    li {
      margin: 0 32px;
      height: 72px;
      line-height: 72px;
      color: #000000;
      font-family: "HarmonyOS_Sans_SC_Regular";
      font-size: 26px;
      text-align: center;

      border-bottom: 1px solid #d5d8e0;

      &:last-child {
        border-width: 0;
      }
    }
  }
}

.qr_bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.qr {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 100%;
    height: auto;
  }
}

.ol_bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 142px;
  left: 0;
  z-index: 99;
  height: auto !important;
}
</style>
