<template>
  <div class="wrap_1" v-if="swiperList.length > 0">
    <div class="wrap_2">
      <div class="wrap_3">
        <swiper class="swiper" :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="item in swiperList" :key="item.title">
            <img :src="item.data.picture" />
            <div class="click" @click="handleExplore(item)" v-if="item.data.url">
              <span>点击探索</span>
              <img src="@/assets/home/arraw_m.png" alt="">
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import VueAwesomeSwiper, { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import banner1 from '@/assets/home/home_2_1_0511.png'
import banner2 from '@/assets/home/home_2_2_0511.png'
import { getStarModels } from '@/request/api';
Vue.use(VueAwesomeSwiper)

export default {
  name: 'SwiperCar',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    const that = this
    return {
      swiperList: [
        // {
        //   img: banner1,
        //   text: '花仙子',
        //   link: '/PixieDetail',
        // }, {
        //   img: banner2,
        //   text: 'CiCi',
        //   link: '/CICIDetail',
        // }
      ],
      swiperOption: {
        direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 0,
        // loop: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'custom',
          clickable: true,
          renderCustom: function (swiper, current, total) {
            let html = ''
            for (let i = 1; i <= total; i++) {
              if (current === i) {
                html += `<div class="swiper-pagination-bullet swiper-pagination-bullet-active">${that.swiperList[i - 1]?.title}</div>`
              } else {
                html += `<div class="swiper-pagination-bullet">${that.swiperList[i - 1]?.title}</div>`
              }
            }
            return html
          },
        },
      },
    }
  },
  setup() {
    return {};
  },
  methods: {
    handleExplore(item) {
      this.$router.push(item.data.url)
      window._hmt.push(['_trackEvent', '官网首页', '车型探索', item.title]);
    },
  },
  mounted() {
    getStarModels().then(res => {
      console.log(res.data)
      this.swiperList = res.data;
    })
  },

};
</script>
<style scoped lang="scss">
.wrap_1 {
  margin: 88px 125px;
}

.wrap_2 {
  position: relative;
  width: 100%;
  padding-bottom: 51.3142174432497%;
}

.wrap_3 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.swiper {
  position: relative;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination {
  left: 70px;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ::v-deep .swiper-pagination-bullet {
    border: 5px solid #595757;
    border-width: 0 0 0 5px;
    width: 100px;
    height: 84px;
    border-radius: 0;
    opacity: 1;
    background: none;
    margin: 6px 0;
    text-align: left;
    line-height: 84px;
    font-size: 16px;
    color: #595757;
    padding: 0 0 0 20px;
    cursor: pointer;

    &.swiper-pagination-bullet-active {
      border-color: #FFFFFF;
      color: #FFFFFF;
      margin: 8px 0;
    }
  }
}

.click {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 50%;
  bottom: 52px;
  transform: translate(-50%, 0);
  width: 145px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 20px;
  font-family: HarmonyOS_Sans_SC_Medium;
  transition: all 0.3s ease;

  font-size: 12px;
  color: #fff;
  cursor: pointer;

  span {
    margin: 0 2px 0 0;
  }

  img {
    margin: 0 0 0 2px;
    width: 24px;
    height: auto;
  }

  &:hover {
    background: #E4B756;
    border: 1px solid #E4B756;
    transform: translate(-50%, 0) scale(1.1);
    color: #fff;
  }
}
</style>
