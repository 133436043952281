<template>
  <div
    class="HomeglobalStore"
  >
    <IsVisible
      @visible="handleVisible(true)"
      @leave="handleVisible(false)"
      :class="{ 'animate__animated  animate__fadeInUp': visible }"
    >
      <ul class="HomeGlobal" ref="HomeGlobal">
        <li
          v-for="(item, index) in globalList"
          :key="item.text"
          :class="{ active: activeIndex === index }"
          @click="toggle(index)"
          @mouseover="toggle(index)"
          @mouseout="toggle()"
        >
            <img :src="item.img" alt="">
            <span class="text">{{ item.text }}</span>
        </li>
      </ul>
    </IsVisible>
    <IsVisible
      @visible="handleScroll(true)"
    >
      <div class="HomeStore" ref="HomeStore">
        <ul>
          <li v-for="(item, idx) in list" class="item" :key="idx">
            <span class="text">
              <CountUp
                :endVal="item.num"
                :options="options"
              />
              <b>{{item?.uitl}}</b>
            </span>
            <span class="num">{{item?.text}}</span>
          </li>
        </ul>
      </div>
    </IsVisible>
  </div>
</template>
<script>
import CountUp from 'vue-countup-v2'
import IsVisible from '@/components/IsVisible'
import Img1 from '@/assets/new/citys/city_1.jpg'
import Img2 from '@/assets/new/citys/city_2.jpg'
import Img3 from '@/assets/new/citys/city_3.jpg'
import Img4 from '@/assets/new/citys/city_4.jpg'
import Img5 from '@/assets/new/citys/city_5.jpg'
import Img6 from '@/assets/new/citys/city_6.jpg'
import Img7 from '@/assets/new/citys/city_7.jpg'
import Img8 from '@/assets/new/citys/city_8.jpg'
import Img9 from '@/assets/new/citys/city_9.jpg'
import Img10 from '@/assets/new/citys/city_10.jpg'

import { getConfig } from '@/request/api'
export default {
  name: 'HomeStore',
  components: {
    CountUp,
    IsVisible,
  },
  data() {
    return {
      visible: false,
      activeIndex: -1,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
        // 动画持续时间
        duration: 10,
      },
      globalList: [
        {
          text: 'THE UNITED STATES OF AMERICA',
          img: Img1,
        }, {
          text: 'SPAIN',
          img: Img2,
        }, {
          text: 'THE NETHERLANDS',
          img: Img3,
        }, {
          text: 'THE REPUBLIC OF KOREA',
          img: Img4,
        }, {
          text: 'GERMANY',
          img: Img5,
        }, {
          text: 'BRITAIN',
          img: Img6,
        }, {
          text: 'ITALY',
          img: Img7,
        }, {
          text: 'THAILAND',
          img: Img8,
        }, {
          text: 'GREECE',
          img: Img9,
        }, {
          text: 'EGYPT',
          img: Img10,
        }
      ],
      num: [0, 0, 0],
      list: [],
    }
  },
  setup() {
    return {};
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getConfig().then(res => {
        this.list = res.data[0].data;
        // console.log('store.vue');
        // console.log(this.list);
        this.list.map(v => (v.num = parseInt(v.num)))
      })
    },
    handleVisible(visible) {
      if (visible) {
        this.visible = visible
      }
    },
    handleScroll() {
      this.num = this.list.map(v => (v.num * 1))
    },
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? -1 : index
    },
  },
};
</script>
<style scoped lang="scss">
.HomeGlobalStore {
  width: 100%;
  min-width: 960px;
  height: calc(100vh - 60px);
}
.HomeGlobal {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 960px;
  height: calc(100vh - 60px - 32vh);

  li {
    position: relative;
    width: calc(100% / 10);
    // transition: all 0.5s ease-in;
    transition: all 0.5s ease;
  }

  span {
    position: absolute;
    top: 40px;
    left: 50%;
    // transform: translate(-50%, 0) rotate(90deg);
    transform: rotate(90deg);
    transform-origin: top left;
    margin: 0 0 0 10px;
    color: #fff;
    font-size: 18px;
    line-height: 20px;
    height: 20px;
    white-space: nowrap;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .active {
    width: 268px;
  }
}
.HomeStore {
  position: relative;
  width: 100%;
  min-width: 960px;
  // height: calc(100vh - 60px);
  // height: calc(100vh - 60px - 60vh);
  height: 32vh;
  background: url('@/assets/home/store_pc.png') no-repeat;
  background-position: center;
  background-size: cover;

  ul {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0px;
    height: 80px;
    margin: -40px 0 0;

    .item {
      display: inline-block;
      margin: 0 50px;
      color: #fff;

      .text {
        font-size: 40px;
      }

      .num {
        font-size: 14px;
        display: block;
        margin-top: 10px;

      }

      b {
        font-weight: normal;
        font-size: 20px;
        margin-left: 10px;
      }
    }
  }
}
</style>
